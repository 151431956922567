<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center p-4">
    <div v-if="!$store.state.kierowca_id">
      <div class="text-center mb-0">
        <img class="mb-3" style="width: 100px;height: 100px;" src="/img/icons/android-chrome-192x192.png">
        <h1 class="mb-0">Wprowadź pin kierowcy</h1>
      </div>
      <Pinpad @input="loginQrcode"></Pinpad>
      <!-- <button @click="checkPin">DEV: Ustaw kierowce nr 3</button> -->
    </div>
    <div v-else>
      Kierowca ID {{ $store.state.kierowca_id }}
      <router-link to="/" class="btn btn-primary">Rozpocznij</router-link>
    </div>
  </div>
</template>
<script>
import Pinpad from "../components/Pinpad.vue";
import api from "../api/myApi";

export default {
  name: "StartPinView",
  components: { Pinpad },
  props: {
    msg: String,
  },
  computed: {},
  methods: {
    loginQrcode: function (result) {
      if (result == '999999999') {
        this.checkPin();
        return;
      }
      let vm=this;
      api.getKierowcaByPin(result).then(
        (response) => {
          vm.$store.commit("setKierowcaId", response.data.kierowcy_id);
          vm.$store.dispatch("complaint/setLastNumber", response.data.complaint_last_number);

          vm.$router.push("/start/karta");
        },
        (error) => {
          if (error.response && error.response.status == 422) {
            return;
          }
          this.pin = "";
        }
      );
    },

    checkPin() {
      this.$store.commit("setKierowcaId", 3);
      this.$router.push("/start/karta");
    },
  },
  mounted() {
    // this.$store.dispatch('getKartaDrogowa')
  },
};
</script>

<style>

</style>
