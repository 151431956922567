<template>
    <section class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
        v-if="!isLogged">

        <div class="w-100 d-flex justify-content-between" style="background: #198754; color: white;">
            <h2 class="p-2 text-center w-100" @click="doLoginView = false; showTextarea = false">Magico GreenApp</h2>
        </div>

        <div v-if="!doLoginView" class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-center justify-content-center"
            style="overflow: auto; ">
            <a @click.prevent="doLoginView = true" class="btn btn-success btn-lg">Zaloguj do aplikacji</a>
        </div>

        <div v-if="doLoginView" class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-center justify-content-center"
            style="overflow: auto; ">
            <div v-if="$store.state.hasInternet && loginChecked">
                <h1 class="my-4">Połącz aplikację z firmą !</h1>
                <p>Dodaj nowy telefon i zeskanuj kod autoryzacyjny, który wyświeli się po dodaniu telefonu</p>
                <div class="row">
                    <div class="col-6">
                        <CameraScanner :custom='true' @input="proccessJsonCredentials">
                            <button class="btn btn-success ">Skanuj kod logowania</button>
                        </CameraScanner>
                    </div>
                    <div class="col-6">
                        <button class="col btn btn-secondary" @click="openTextarea">lub podaj dane
                            ręcznie</button>
                    </div>
                </div>
                <div v-show="showTextarea">
                    <textarea ref="textarea" placeholder="Wklej tutaj dane z panelu" class="form-control mb-2 mt-2"
                        v-model="jsonTextarea"></textarea>
                    <button class="btn btn-outline-primary w-100" @click="proccessJsonCredentials(jsonTextarea)"
                        :disabled="!jsonTextarea">Zaloguj</button>
                </div>
            </div>
            <div v-if="!$store.state.hasInternet">
                <h1>Brak połączenia z internetem</h1>
                <p>Nasza aplikacja potrzebuje aktywanego połączenia z internetem aby można było z niej korzystać</p>
            </div>
        </div>
        <div class="w-100 text-secondary bg-gray form-text bg-light text-center p-2">
            Magico Software
        </div>
    </section>

    <section class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative" v-else>
        <router-view :key="$route.fullPath" v-if="isLoaded"></router-view>
        <div v-else class="h-100 d-flex align-items-center justify-content-center">Ładowanie danych</div>
        <div v-if="!$store.state.hasInternet" class="alert_internet">Brak połączenia z internetem</div>
</section>
</template>
<script>
import CameraScanner from './components/CameraScanner'
import authAxos from '@/api/authAxios'
import myApi from './api/myApi';
export default {
    name: 'AppLogin',
    mounted() {
        console.log('Component mounted.')
        this.checkLogged();
        let vm = this;
        setTimeout(function () {
            vm.checkHasInternet();
        }, 500);
        if (localStorage.getItem('color-mode') == 'dark') {
            document.documentElement.classList.add("dark");
        }

    },
    components: { CameraScanner },
    data: function () {
        return {
            doLoginView: false,
            showTextarea: false,
            jsonTextarea: '',
            isLogged: false,
            loaded: false,
            isLoaded: false,
            qrCodeLoading: true,
            hasInternet: true,
            loginChecked: false,
        }
    },
    computed: {

    },
    methods: {
        checkHasInternet: function () {

            console.log('ineternetCHeck');
            this.$store.commit('setHasInternet', navigator.onLine);
            console.log(navigator.onLine);
            let vm = this;
            window.addEventListener("online", function () {
                console.log('online event')
                vm.checkLogged();
                vm.$store.commit('setHasInternet', true);
                vm.$store.dispatch('car/syncState');

            }, false);
            window.addEventListener("offline", function () {
                console.log('offline event')
                vm.$store.commit('setHasInternet', false);
            }, false);
        },
        fixInternet: function () {
            this.$store.commit('setHasInternet', navigator.onLine);
        },
        checkLogged: function () {
            if (localStorage.client_id && localStorage.client_secret) {
                this.getToken();
                // alert('jest token');
            } else {
                this.isLogged = false;
                this.loaded = true;
                this.loginChecked = true;
            }
        },
        scan: function () {
            // let vm = this;
            // cordova.plugins.barcodeScanner.scan(
            //     function (result) {
            //         vm.proccessJsonCredentials(result.text);
            //     },
            //     function (error) {
            //         alert("Scanning failed: " + error);
            //     }
            // );
        },
        proccessJsonCredentials: function (json) {
            let vm = this;
            try {
                var data = JSON.parse(json);
                if (data.client_id && data.client_secret) {
                    localStorage.client_id = data.client_id;
                    localStorage.client_secret = data.client_secret;
                    vm.getToken();
                } else {
                    alert('No valid data schema');
                }
            } catch (e) {
                console.log(e);
                alert('No valid json');
            }
        },
        getMe: function () {
            let vm = this;
            var token = localStorage.getItem('access_token');
            if (token) {
                myApi.getMe().then(response => {
                    //ładowanie zmiennych po starcie
                    this.$store.commit('setHasInternet', true);
                    this.$store.commit('setPojazd', response.data.pojazd);
                    this.$store.commit('setKierowca', response.data.kierowca);
                    this.$store.commit('setKarta', response.data.karta);
                    this.$store.dispatch("fetchOrders");
                    this.$store.dispatch('fetchKartaHtml');
                    vm.isLoaded = true;

                }).catch(() => {
                    vm.$store.commit('setHasInternet', false);
                    vm.isLoaded = true;
                })
            }
        },
        openTextarea: function () {
            this.showTextarea = true;
            setTimeout(function () {
                this.$refs.textarea.focus()
            }.bind(this), 500);
        },
        getToken: function () {

            if (!localStorage.client_id || !localStorage.client_secret) {
                console.warn('try to get access token with no credentials')
            }
            let vm = this;
            authAxos.getToken().then(response => {
                vm.$store.commit('setHasInternet', true);
                console.log(response.data);
                //alert(response.data.access_token)
                localStorage.setItem('access_token', response.data.access_token);
                vm.isLogged = true;
                vm.getMe();
                vm.loginChecked = true;
            }, () => {
                if (navigator.onLine && localStorage.getItem('access_token')) {
                    vm.isLogged = true;
                    vm.isLoaded = true;
                } else {
                    vm.isLogged = false;
                }
                vm.loginChecked = true;
            })
        }
    }
}
</script>
<style>
.alert_internet {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    text-align: center;
    background-color: red;
    padding-top: 4px;
}

.swal2-html-container {
    text-align: center;
}

.bg-lightgreen {
    background-color: rgb(232, 252, 255);
}

.loader-center {
    position: fixed;
    top: 50%;
    left: 50%;
}

@media screen and (max-width:340px) {
  html, body{
    font-size: 14px !important;
  }
}
</style>